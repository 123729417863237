import 'ol/ol.css';
import Geolocation from 'ol/Geolocation';

/*geolocation.on('change', function () {
  geolocation.setTracking(false);

  alert(geolocation.getPosition());

  //alert(geolocation.getAccuracy());

});*/

window.funcao = function funcao() {
  // Initialize
  var geolocation = new Geolocation({
    trackingOptions: {
      enableHighAccuracy: true,
    }
  });

  //geolocation.setTracking(true);

  return geolocation;
}




//pos.on('change', function () {
  //pos.setTracking(false);

  //alert(pos.getPosition());

  //alert(geolocation.getAccuracy());

//});

//console.log(pos);



    



